@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.accordion-button-pagos:not(.collapsed) {
  color: black !important;
  background-color: #fafafa;
}

.accordion-button-pagos {
  background-color: #fafafa;
}

.accordion-item-pagos:first-of-type .accordion-button-pagos {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  box-shadow: inset 0 -1px 0 #fafafa;
}


.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #e5e7eb;
}

.accordion-button {
  background-color: #e5e7eb;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  box-shadow: inset 0 -1px 0 #e5e7eb;
}

.blue1{
  background-color: #73A3D5;
}

.blue1color{
  color: #73A3D5;
}

.blue2{
  color:#1392ce
}

.blue2bg{
  background-color: #1392ce;
}

.blue3{
  background-color: #0172ac;
}

.blue3bg{
  background-color: #0172ac;
}
.gris1{
  background-color: #f2f2f2;
}

.gris2{
  color: #b2b2b2;
}

.gris2bg{
  background-color: #b2b2b2;
}

.gris3{
  background-color: #eaecec;
}

.gris3c{
  color: #eaecec;
}


.gris4{
  background-color: #e5e5e5;
}

.gris5bg{
  background-color: #f7f7f8;
}

.gris6bg{
  background-color: #fafafa;
}

.gris7bg {
  background-color: #686866;
}

.gris7 {
  color: #686866;
}

.gris8{
  color: #a7a7a7;
}

.box {
  position: absolute;
  left: 43%;
  top: 40%;
}

.side {
  box-sizing: border-box;
  height: 55px;
}

.top {
  position:absolute;
  width: 300px;
  height: 28px;
  top:0px;
  bottom: 0px;
  left: -55px;
  transform: skew(-65deg, 0deg);
  background: #51aef9;
}

.left {
  position: absolute;
  width: 300px;
  height: 80px;
  transform: skew(0deg, 0deg);
  background: #8ffce7;
  top: 29px;
  right: -215px;
}

.right {
  position: absolute;
  bottom: 0px;
  left: 216px;
  top: 15px;
  width: 60px;
  height: 80px;
  transform: skew(0, -25deg);
  background: #3460f2;
}

.gris8bg{
  background-color: #9d9d9d;
}


.gris9{
  color:#d9d9db
}

.gris9bg{
  background-color:#d9d9db ;
}

.morado1{
  color: #8378dd;
}

.morado1bg{
  background-color: #7e72de;
}

.morado2 {
  background-color: #5f50d7;
}

.morado2color {
  color: #5f50d7;
}

.morado3 {
  color: #7f73df;
}

.morado4-bg{
  background-color: #6f6fdd;
}

.morado3bg { 
  background-color: #7f73df;
}

.naranja1 {
  background-color: #d29862;
}

.naranja2bg {
  background-color: #d7af8b;
}

.naranja2 {
  color: #c59667
}

.blueModal {
  background-color: #48B7EB;
}

.amarillo {
  background-color: #f9be18;
}

.circle { 
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.circle-check{
  background-color: #5f50d7;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.stickyHeader{
  position: sticky;
  top: 0;
}

.side {
  box-sizing: border-box;
  height: 55px;
}

.top {
  position:absolute;
  width: 400px;
  height: 28px;
  top:0px;
  bottom: 0px;
  left: -153px;
  transform: skew(-65deg, 0deg);
  background: #7873e5;
}

.left {
  position: absolute;
  width: 400px;
  height: 80px;
  transform: skew(0deg, 0deg);
  background: #5f50d7;
  top: 28.5px;
  right: -216px;
}

.right {
  position: absolute;
  bottom: 0px;
  left: 216px;
  top: 15px;
  width: 60px;
  height: 80px;
  transform: skew(0, -25deg);
  background: #7873e5;
}
